import React from 'react';
import { Image, LinkBase, Text } from '@belong/ui';
import clsx from 'clsx';
import styles from './tn-market-banner.module.css';

export function TnMarketBanner() {
  return (
    <div className={clsx('relative flex justify-between z-50 w-full', styles.banner)}>
      <div className="flex md:flex-1 md:justify-end overflow-hidden md:mr-2xl absolute md:relative left-0 bottom-0 h-full">
        <Image
          className="max-w-none w-auto md:hidden h-full"
          src="banners/tennessee/banner-left-mobile.svg"
          alt=""
          aria-hidden="true"
        />
        <Image
          className="hidden md:block w-auto max-w-none"
          src="banners/tennessee/banner-left.svg"
          alt=""
          aria-hidden="true"
        />
      </div>
      <LinkBase
        href="/blog/belong-expands-to-tennessee"
        className="flex flex-col flex-3 md:flex-1 md:flex-row items-center justify-center text-center leading-p1 gap-y-2xs md:whitespace-nowrap z-10"
      >
        <Text className="text-white leading-p1 px-2xl md:px-0">
          We&apos;ve added <strong>Tennessee</strong>
          <br className="inline md:hidden" />
          &nbsp;to&nbsp;Belong!{' '}
        </Text>
        <Text
          fontWeight="semibold"
          className="text-green leading-p1 pl-xs hover:text-light-green z-20"
          style={{ textShadow: '2px 0px 10px #3EE492CC;', transition: 'all 0.1s ease-in-out' }}
        >
          LEARN MORE
        </Text>
      </LinkBase>
      <div className="flex md:flex-1 items-end md:justify-start overflow-hidden md:ml-2xl absolute md:relative right-0 bottom-0 h-full">
        <Image
          className="max-w-none w-auto md:hidden h-full"
          src="banners/tennessee/banner-right-mobile.svg"
          alt=""
          aria-hidden="true"
        />
        <Image
          className="hidden md:block w-auto max-w-none"
          src="banners/tennessee/banner-right.svg"
          alt=""
          aria-hidden="true"
        />
      </div>
    </div>
  );
}
